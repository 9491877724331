<template>
  <div class="flex flex-wrap gap-4 items-center">
    <b-input-group
      v-for="filter in activeFilters"
      :key="filter.text"
      size="sm"
      :prepend="filter.text"
      class="w-auto"
    >
      <b-input-group-append>
        <b-button
          variant="primary"
          size="sm"
          @click="filter.fn"
          class="w-4 flex justify-center items-center"
          title="Remove filter"
        >
          &times;
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'ActiveFilters',
  components: {},
  props: {
    activeFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
